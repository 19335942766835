.workout-techniques,
.new-workout-properties, 
.new-workout-submit {
    margin-top: 20px;
}

.workout-technique:first-child {
    border-top: 1px solid #ccc;
    border-top-left-radius: 7.5px;
    border-top-right-radius: 7.5px;
}

.workout-technique:last-child {
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
}

.workout-technique {
    font-size: 12pt;
    padding: 10px 0;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    margin: 0;
}

.workout-technique-remove {
    text-align: right;
}