.drill-setup-container {
    margin: 20px 0;
}

.rank-picker {
    max-height: 200px;
    overflow: auto;
}

.row.start-button {
    margin-top: 20px;
}